/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { 
  RiArrowLeftSLine,
  RiCheckboxCircleLine,
  RiTimeLine,
  RiMoneyDollarCircleLine,
  RiLineChartLine,
  RiBuildingLine,
  RiTeamLine,
  RiShieldCheckLine,
  RiCalendarCheckLine,
  RiMailLine
} from "react-icons/ri"

const HRSoftwareSelectionPage = () => {
  return (
    <Layout>
      <SEO 
        title="HR Software Selection Services | Expert Guidance for HRIS, ATS & More"
        description="Get expert guidance selecting the perfect HR software for your organization. Our consultants help you navigate HRIS, ATS, performance management & more with an unbiased approach."
        keywords={[
          "HR software selection",
          "HRIS selection consultant",
          "applicant tracking system selection",
          "HR technology selection",
          "HR software implementation",
          "HR technology consultant",
          "HR systems selection",
          "best HR software for business"
        ]}
        canonical="https://www.performancereviewssoftware.com/services/hr-software-selection/"
      />
      
      <div sx={{
        bg: 'primaryMuted',
        pt: [3, 4],
        pb: [3, 4]
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3
        }}>
          {/* Navigation Link */}
          <div sx={{
            display: 'flex',
            alignItems: 'center',
            mb: 4
          }}>
            <Link to="/services" sx={{
              display: 'flex',
              alignItems: 'center',
              color: 'primary',
              textDecoration: 'none',
              fontSize: '1rem',
              fontWeight: 500
            }}>
              <RiArrowLeftSLine sx={{ mr: 1 }} /> Back to Services
            </Link>
          </div>
          
          {/* Hero Section */}
          <div sx={{ mb: 4 }}>
            <h1 sx={{
              fontSize: ['1.8rem', '2.3rem'],
              fontWeight: 800,
              lineHeight: 1.2,
              mb: 3,
              color: 'primary',
              maxWidth: '800px',
              mx: 'auto',
              textAlign: 'center'
            }}>
              Expert HR Software Selection Services to Find Your Perfect Solution
            </h1>
            
            <p sx={{
              fontSize: ['1.1rem', '1.2rem'],
              maxWidth: '800px',
              mb: 3,
              lineHeight: 1.6,
              color: 'text',
              mx: 'auto',
              textAlign: 'center'
            }}>
              Navigate the complex HR software landscape with confidence. Our vendor-neutral consultants guide you through 
              a structured selection process to find the HR technology that truly fits your organization's unique needs and budget.
            </p>
            
            <div sx={{
              display: 'flex',
              flexDirection: ['column', 'row'],
              gap: 3,
              mb: 4,
              justifyContent: 'center'
            }}>
              <Link to="/contact" sx={{
                bg: 'primary',
                color: 'white',
                py: 2,
                px: 4,
                borderRadius: '40px',
                fontWeight: 600,
                textDecoration: 'none',
                fontSize: '1rem',
                display: 'inline-block',
                textAlign: 'center',
                transition: 'transform 0.2s, box-shadow 0.2s, background-color 0.2s',
                ':hover': {
                  transform: 'translateY(-3px)',
                  boxShadow: '0 6px 20px rgba(0,0,0,0.2)',
                  bg: 'primaryDark',
                  color: 'white'
                }
              }}>
                Schedule a Free Consultation
              </Link>
              
              <a href="#process" sx={{
                bg: 'transparent',
                color: 'primary',
                py: 2,
                px: 4,
                borderRadius: '40px',
                fontWeight: 600,
                textDecoration: 'none',
                fontSize: '1rem',
                display: 'inline-block',
                textAlign: 'center',
                border: '2px solid',
                borderColor: 'primary',
                transition: 'transform 0.2s, box-shadow 0.2s',
                ':hover': {
                  transform: 'translateY(-3px)',
                  boxShadow: '0 6px 20px rgba(0,0,0,0.1)'
                }
              }}>
                Learn About Our Process
              </a>
            </div>
            
            <div sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 4,
              maxWidth: '800px',
              justifyContent: 'center',
              mx: 'auto'
            }}>
              <div sx={{ 
                display: 'flex', 
                alignItems: 'center',
                fontSize: '0.9rem'
              }}>
                <RiCheckboxCircleLine sx={{ mr: 1, color: 'success' }} /> 
                Vendor-neutral approach
              </div>
              <div sx={{ 
                display: 'flex', 
                alignItems: 'center',
                fontSize: '0.9rem'
              }}>
                <RiCheckboxCircleLine sx={{ mr: 1, color: 'success' }} /> 
                Save 30-50% on implementation costs
              </div>
              <div sx={{ 
                display: 'flex', 
                alignItems: 'center',
                fontSize: '0.9rem'
              }}>
                <RiCheckboxCircleLine sx={{ mr: 1, color: 'success' }} /> 
                Reduce selection time by 40%
              </div>
              <div sx={{ 
                display: 'flex', 
                alignItems: 'center',
                fontSize: '0.9rem'
              }}>
                <RiCheckboxCircleLine sx={{ mr: 1, color: 'success' }} /> 
                Detailed requirements analysis
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* Software Platforms Section */}
      <div sx={{
        maxWidth: '1200px',
        mx: 'auto',
        py: 4,
        px: 3
      }}>
        <h2 sx={{
          fontSize: ['1.6rem', '1.8rem'],
          fontWeight: 700,
          mb: 3,
          textAlign: 'center'
        }}>
          HR Software Platforms We Help You Select
        </h2>
        
        <p sx={{ 
          textAlign: 'center', 
          maxWidth: '800px', 
          mx: 'auto', 
          mb: 4,
          fontSize: '1.1rem'
        }}>
          Our consultants have deep expertise across the entire HR technology ecosystem, helping you navigate options and find the perfect fit for your needs.
        </p>
        
        <div sx={{
          display: 'grid',
          gridTemplateColumns: ['1fr', null, '1fr 1fr', 'repeat(3, 1fr)'],
          gap: 3,
          mb: 4
        }}>
          <div sx={{
            bg: 'white',
            borderRadius: '10px',
            p: 4,
            boxShadow: '0 4px 12px rgba(0,0,0,0.06)',
            transition: 'transform 0.2s',
            ':hover': {
              transform: 'translateY(-5px)',
              boxShadow: '0 8px 24px rgba(0,0,0,0.1)'
            }
          }}>
            <h3 sx={{ 
              fontSize: '1.3rem', 
              fontWeight: 600, 
              mb: 3,
              color: 'primary' 
            }}>
              HRIS & HCM Systems
            </h3>
            <p sx={{ fontSize: '0.95rem', mb: 3, lineHeight: 1.6 }}>
              From core employee data management to comprehensive Human Capital Management platforms, we help you find the system that grows with your organization.
            </p>
            <Link to="/software/hris" sx={{
              color: 'primary',
              fontWeight: 500,
              textDecoration: 'none',
              fontSize: '0.95rem',
              ':hover': {
                textDecoration: 'underline'
              }
            }}>
              Compare HRIS options →
            </Link>
          </div>
          
          <div sx={{
            bg: 'white',
            borderRadius: '10px',
            p: 4,
            boxShadow: '0 4px 12px rgba(0,0,0,0.06)',
            transition: 'transform 0.2s',
            ':hover': {
              transform: 'translateY(-5px)',
              boxShadow: '0 8px 24px rgba(0,0,0,0.1)'
            }
          }}>
            <h3 sx={{ 
              fontSize: '1.3rem', 
              fontWeight: 600, 
              mb: 3,
              color: 'primary' 
            }}>
              Applicant Tracking Systems
            </h3>
            <p sx={{ fontSize: '0.95rem', mb: 3, lineHeight: 1.6 }}>
              Find recruitment software that streamlines your hiring process, improves candidate experience, and integrates with your existing HR ecosystem.
            </p>
            <Link to="/software/applicant-tracking-system" sx={{
              color: 'primary',
              fontWeight: 500,
              textDecoration: 'none',
              fontSize: '0.95rem',
              ':hover': {
                textDecoration: 'underline'
              }
            }}>
              Compare ATS options →
            </Link>
          </div>
          
          <div sx={{
            bg: 'white',
            borderRadius: '10px',
            p: 4,
            boxShadow: '0 4px 12px rgba(0,0,0,0.06)',
            transition: 'transform 0.2s',
            ':hover': {
              transform: 'translateY(-5px)',
              boxShadow: '0 8px 24px rgba(0,0,0,0.1)'
            }
          }}>
            <h3 sx={{ 
              fontSize: '1.3rem', 
              fontWeight: 600, 
              mb: 3,
              color: 'primary' 
            }}>
              Performance Management
            </h3>
            <p sx={{ fontSize: '0.95rem', mb: 3, lineHeight: 1.6 }}>
              Select tools that align with your performance philosophy, whether traditional reviews, continuous feedback, or OKR-based approaches.
            </p>
            <Link to="/software/performance-management" sx={{
              color: 'primary',
              fontWeight: 500,
              textDecoration: 'none',
              fontSize: '0.95rem',
              ':hover': {
                textDecoration: 'underline'
              }
            }}>
              Compare performance tools →
            </Link>
          </div>
          
          <div sx={{
            bg: 'white',
            borderRadius: '10px',
            p: 4,
            boxShadow: '0 4px 12px rgba(0,0,0,0.06)',
            transition: 'transform 0.2s',
            ':hover': {
              transform: 'translateY(-5px)',
              boxShadow: '0 8px 24px rgba(0,0,0,0.1)'
            }
          }}>
            <h3 sx={{ 
              fontSize: '1.3rem', 
              fontWeight: 600, 
              mb: 3,
              color: 'primary' 
            }}>
              Learning Management
            </h3>
            <p sx={{ fontSize: '0.95rem', mb: 3, lineHeight: 1.6 }}>
              Identify LMS platforms that deliver engaging training experiences, track compliance, and develop your workforce effectively.
            </p>
            <Link to="/software/learning-management-system" sx={{
              color: 'primary',
              fontWeight: 500,
              textDecoration: 'none',
              fontSize: '0.95rem',
              ':hover': {
                textDecoration: 'underline'
              }
            }}>
              Compare LMS options →
            </Link>
          </div>
          
          <div sx={{
            bg: 'white',
            borderRadius: '10px',
            p: 4,
            boxShadow: '0 4px 12px rgba(0,0,0,0.06)',
            transition: 'transform 0.2s',
            ':hover': {
              transform: 'translateY(-5px)',
              boxShadow: '0 8px 24px rgba(0,0,0,0.1)'
            }
          }}>
            <h3 sx={{ 
              fontSize: '1.3rem', 
              fontWeight: 600, 
              mb: 3,
              color: 'primary' 
            }}>
              Employee Engagement
            </h3>
            <p sx={{ fontSize: '0.95rem', mb: 3, lineHeight: 1.6 }}>
              Find the right tools to measure engagement, gather employee feedback, and build a responsive listening strategy.
            </p>
            <Link to="/software/employee-engagement" sx={{
              color: 'primary',
              fontWeight: 500,
              textDecoration: 'none',
              fontSize: '0.95rem',
              ':hover': {
                textDecoration: 'underline'
              }
            }}>
              Compare engagement platforms →
            </Link>
          </div>
          
          <div sx={{
            bg: 'white',
            borderRadius: '10px',
            p: 4,
            boxShadow: '0 4px 12px rgba(0,0,0,0.06)',
            transition: 'transform 0.2s',
            ':hover': {
              transform: 'translateY(-5px)',
              boxShadow: '0 8px 24px rgba(0,0,0,0.1)'
            }
          }}>
            <h3 sx={{ 
              fontSize: '1.3rem', 
              fontWeight: 600, 
              mb: 3,
              color: 'primary' 
            }}>
              Time & Attendance
            </h3>
            <p sx={{ fontSize: '0.95rem', mb: 3, lineHeight: 1.6 }}>
              Select time tracking and scheduling solutions that reduce compliance risk, optimize workforce costs, and enhance employee experience.
            </p>
            <Link to="/software/time-attendance" sx={{
              color: 'primary',
              fontWeight: 500,
              textDecoration: 'none',
              fontSize: '0.95rem',
              ':hover': {
                textDecoration: 'underline'
              }
            }}>
              Compare time & attendance tools →
            </Link>
          </div>
        </div>
      </div>
      
      {/* Our Selection Process Section */}
      <div id="process" sx={{
        bg: 'background',
        py: 4
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3
        }}>
          <h2 sx={{
            fontSize: ['1.6rem', '1.8rem'],
            fontWeight: 700,
            mb: 2,
            textAlign: 'center'
          }}>
            Our Proven HR Software Selection Process
          </h2>
          
          <p sx={{ 
            textAlign: 'center', 
            maxWidth: '800px', 
            mx: 'auto', 
            mb: 4,
            fontSize: '1.1rem'
          }}>
            We guide you through a structured, vendor-neutral approach that ensures you select the right solution for your organization's specific needs.
          </p>
          
          <div sx={{
            display: 'grid',
            gridTemplateColumns: ['1fr', null, '1fr 1fr', 'repeat(3, 1fr)'],
            gap: [3, 4],
            mb: 4
          }}>
            <div sx={{
              position: 'relative',
              bg: 'white',
              borderRadius: '12px',
              p: 4,
              boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
              transition: 'transform 0.2s',
              ':hover': {
                transform: 'translateY(-5px)',
                boxShadow: '0 8px 24px rgba(0,0,0,0.08)'
              }
            }}>
              <div sx={{
                position: 'absolute',
                top: '-20px',
                left: '50%',
                transform: 'translateX(-50%)',
                bg: 'primary',
                color: 'white',
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontWeight: 'bold',
                fontSize: '1.2rem'
              }}>
                1
              </div>
              <h3 sx={{ 
                fontSize: '1.2rem', 
                fontWeight: 600, 
                mt: 3,
                mb: 3,
                textAlign: 'center',
                color: 'primary' 
              }}>
                Needs Assessment
              </h3>
              <p sx={{ fontSize: '0.95rem', lineHeight: 1.6 }}>
                We begin by understanding your current systems, pain points, and organizational goals through stakeholder interviews and process reviews.
              </p>
              <ul sx={{ pl: 4, mt: 3, mb: 0 }}>
                <li sx={{ mb: 2, fontSize: '0.95rem' }}>Stakeholder interviews</li>
                <li sx={{ mb: 2, fontSize: '0.95rem' }}>Process documentation</li>
                <li sx={{ mb: 2, fontSize: '0.95rem' }}>Current state analysis</li>
                <li sx={{ mb: 0, fontSize: '0.95rem' }}>Future state vision</li>
              </ul>
            </div>
            
            <div sx={{
              position: 'relative',
              bg: 'white',
              borderRadius: '12px',
              p: 4,
              boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
              transition: 'transform 0.2s',
              ':hover': {
                transform: 'translateY(-5px)',
                boxShadow: '0 8px 24px rgba(0,0,0,0.08)'
              }
            }}>
              <div sx={{
                position: 'absolute',
                top: '-20px',
                left: '50%',
                transform: 'translateX(-50%)',
                bg: 'primary',
                color: 'white',
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontWeight: 'bold',
                fontSize: '1.2rem'
              }}>
                2
              </div>
              <h3 sx={{ 
                fontSize: '1.2rem', 
                fontWeight: 600, 
                mt: 3,
                mb: 3,
                textAlign: 'center',
                color: 'primary' 
              }}>
                Requirements Definition
              </h3>
              <p sx={{ fontSize: '0.95rem', lineHeight: 1.6 }}>
                We develop detailed functional and technical requirements, prioritized based on your organization's unique needs.
              </p>
              <ul sx={{ pl: 4, mt: 3, mb: 0 }}>
                <li sx={{ mb: 2, fontSize: '0.95rem' }}>Must-have vs. nice-to-have features</li>
                <li sx={{ mb: 2, fontSize: '0.95rem' }}>Functionality prioritization</li>
                <li sx={{ mb: 2, fontSize: '0.95rem' }}>Technical specifications</li>
                <li sx={{ mb: 0, fontSize: '0.95rem' }}>Integration requirements</li>
              </ul>
            </div>
            
            <div sx={{
              position: 'relative',
              bg: 'white',
              borderRadius: '12px',
              p: 4,
              boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
              transition: 'transform 0.2s',
              ':hover': {
                transform: 'translateY(-5px)',
                boxShadow: '0 8px 24px rgba(0,0,0,0.08)'
              }
            }}>
              <div sx={{
                position: 'absolute',
                top: '-20px',
                left: '50%',
                transform: 'translateX(-50%)',
                bg: 'primary',
                color: 'white',
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontWeight: 'bold',
                fontSize: '1.2rem'
              }}>
                3
              </div>
              <h3 sx={{ 
                fontSize: '1.2rem', 
                fontWeight: 600, 
                mt: 3,
                mb: 3,
                textAlign: 'center',
                color: 'primary' 
              }}>
                Vendor Research
              </h3>
              <p sx={{ fontSize: '0.95rem', lineHeight: 1.6 }}>
                We leverage our industry knowledge to identify potential solutions that align with your requirements and budget.
              </p>
              <ul sx={{ pl: 4, mt: 3, mb: 0 }}>
                <li sx={{ mb: 2, fontSize: '0.95rem' }}>Market analysis</li>
                <li sx={{ mb: 2, fontSize: '0.95rem' }}>Solution shortlisting</li>
                <li sx={{ mb: 2, fontSize: '0.95rem' }}>Preliminary budget alignment</li>
                <li sx={{ mb: 0, fontSize: '0.95rem' }}>Vendor credential verification</li>
              </ul>
            </div>
            
            <div sx={{
              position: 'relative',
              bg: 'white',
              borderRadius: '12px',
              p: 4,
              boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
              transition: 'transform 0.2s',
              ':hover': {
                transform: 'translateY(-5px)',
                boxShadow: '0 8px 24px rgba(0,0,0,0.08)'
              }
            }}>
              <div sx={{
                position: 'absolute',
                top: '-20px',
                left: '50%',
                transform: 'translateX(-50%)',
                bg: 'primary',
                color: 'white',
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontWeight: 'bold',
                fontSize: '1.2rem'
              }}>
                4
              </div>
              <h3 sx={{ 
                fontSize: '1.2rem', 
                fontWeight: 600, 
                mt: 3,
                mb: 3,
                textAlign: 'center',
                color: 'primary' 
              }}>
                Vendor Evaluation
              </h3>
              <p sx={{ fontSize: '0.95rem', lineHeight: 1.6 }}>
                We facilitate structured demos, coordinate vendor responses to your requirements, and create objective comparison tools.
              </p>
              <ul sx={{ pl: 4, mt: 3, mb: 0 }}>
                <li sx={{ mb: 2, fontSize: '0.95rem' }}>RFP development and management</li>
                <li sx={{ mb: 2, fontSize: '0.95rem' }}>Demo script creation</li>
                <li sx={{ mb: 2, fontSize: '0.95rem' }}>Scoring methodology</li>
                <li sx={{ mb: 0, fontSize: '0.95rem' }}>Reference checks</li>
              </ul>
            </div>
            
            <div sx={{
              position: 'relative',
              bg: 'white',
              borderRadius: '12px',
              p: 4,
              boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
              transition: 'transform 0.2s',
              ':hover': {
                transform: 'translateY(-5px)',
                boxShadow: '0 8px 24px rgba(0,0,0,0.08)'
              }
            }}>
              <div sx={{
                position: 'absolute',
                top: '-20px',
                left: '50%',
                transform: 'translateX(-50%)',
                bg: 'primary',
                color: 'white',
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontWeight: 'bold',
                fontSize: '1.2rem'
              }}>
                5
              </div>
              <h3 sx={{ 
                fontSize: '1.2rem', 
                fontWeight: 600, 
                mt: 3,
                mb: 3,
                textAlign: 'center',
                color: 'primary' 
              }}>
                Selection & Negotiation
              </h3>
              <p sx={{ fontSize: '0.95rem', lineHeight: 1.6 }}>
                We help you make the final selection and leverage our industry knowledge to secure optimal pricing and contract terms.
              </p>
              <ul sx={{ pl: 4, mt: 3, mb: 0 }}>
                <li sx={{ mb: 2, fontSize: '0.95rem' }}>Decision matrix facilitation</li>
                <li sx={{ mb: 2, fontSize: '0.95rem' }}>Contract review</li>
                <li sx={{ mb: 2, fontSize: '0.95rem' }}>Pricing optimization</li>
                <li sx={{ mb: 0, fontSize: '0.95rem' }}>Service level agreement review</li>
              </ul>
            </div>
            
            <div sx={{
              position: 'relative',
              bg: 'white',
              borderRadius: '12px',
              p: 4,
              boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
              transition: 'transform 0.2s',
              ':hover': {
                transform: 'translateY(-5px)',
                boxShadow: '0 8px 24px rgba(0,0,0,0.08)'
              }
            }}>
              <div sx={{
                position: 'absolute',
                top: '-20px',
                left: '50%',
                transform: 'translateX(-50%)',
                bg: 'primary',
                color: 'white',
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontWeight: 'bold',
                fontSize: '1.2rem'
              }}>
                6
              </div>
              <h3 sx={{ 
                fontSize: '1.2rem', 
                fontWeight: 600, 
                mt: 3,
                mb: 3,
                textAlign: 'center',
                color: 'primary' 
              }}>
                Implementation Planning
              </h3>
              <p sx={{ fontSize: '0.95rem', lineHeight: 1.6 }}>
                We develop a strategic implementation roadmap to ensure successful deployment and adoption of your new solution.
              </p>
              <ul sx={{ pl: 4, mt: 3, mb: 0 }}>
                <li sx={{ mb: 2, fontSize: '0.95rem' }}>Project planning</li>
                <li sx={{ mb: 2, fontSize: '0.95rem' }}>Resource allocation</li>
                <li sx={{ mb: 2, fontSize: '0.95rem' }}>Change management strategy</li>
                <li sx={{ mb: 0, fontSize: '0.95rem' }}>Success metrics definition</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      
      {/* Benefits Section */}
      <div sx={{
        maxWidth: '1200px',
        mx: 'auto',
        py: 4,
        px: 3
      }}>
        <h2 sx={{
          fontSize: ['1.6rem', '1.8rem'],
          fontWeight: 700,
          mb: 3,
          textAlign: 'center'
        }}>
          Why Choose Our HR Software Selection Services
        </h2>
        
        <div sx={{
          display: 'grid',
          gridTemplateColumns: ['1fr', null, '1fr 1fr', 'repeat(4, 1fr)'],
          gap: 3,
          mb: 4
        }}>
          <div sx={{
            textAlign: 'center',
            p: 3
          }}>
            <div sx={{ 
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '80px',
              height: '80px',
              borderRadius: '50%',
              bg: 'primaryMuted',
              mx: 'auto',
              mb: 3
            }}>
              <RiShieldCheckLine size={36} sx={{ color: 'primary' }} />
            </div>
            <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2 }}>
              Vendor-Neutral Approach
            </h3>
            <p sx={{ fontSize: '0.95rem', lineHeight: 1.6 }}>
              We have no financial ties to vendors, ensuring our advice is based solely on what's best for your organization.
            </p>
          </div>
          
          <div sx={{
            textAlign: 'center',
            p: 3
          }}>
            <div sx={{ 
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '80px',
              height: '80px',
              borderRadius: '50%',
              bg: 'primaryMuted',
              mx: 'auto',
              mb: 3
            }}>
              <RiMoneyDollarCircleLine size={36} sx={{ color: 'primary' }} />
            </div>
            <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2 }}>
              Cost Optimization
            </h3>
            <p sx={{ fontSize: '0.95rem', lineHeight: 1.6 }}>
              Our expertise helps you avoid unnecessary features while securing better pricing and terms, typically saving 15-30% on software costs.
            </p>
          </div>
          
          <div sx={{
            textAlign: 'center',
            p: 3
          }}>
            <div sx={{ 
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '80px',
              height: '80px',
              borderRadius: '50%',
              bg: 'primaryMuted',
              mx: 'auto',
              mb: 3
            }}>
              <RiTimeLine size={36} sx={{ color: 'primary' }} />
            </div>
            <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2 }}>
              Accelerated Process
            </h3>
            <p sx={{ fontSize: '0.95rem', lineHeight: 1.6 }}>
              Our structured approach reduces selection time by 40-60%, helping you move quickly from requirements to implementation.
            </p>
          </div>
          
          <div sx={{
            textAlign: 'center',
            p: 3
          }}>
            <div sx={{ 
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '80px',
              height: '80px',
              borderRadius: '50%',
              bg: 'primaryMuted',
              mx: 'auto',
              mb: 3
            }}>
              <RiLineChartLine size={36} sx={{ color: 'primary' }} />
            </div>
            <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2 }}>
              Higher ROI
            </h3>
            <p sx={{ fontSize: '0.95rem', lineHeight: 1.6 }}>
              Our clients achieve faster implementation, higher adoption rates, and better alignment with business needs.
            </p>
          </div>
        </div>
      </div>
      
      {/* Testimonials Section */}
      <div sx={{
        bg: 'primaryMuted',
        py: 4
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3
        }}>
          <h2 sx={{
            fontSize: ['1.6rem', '1.8rem'],
            fontWeight: 700,
            mb: 4,
            textAlign: 'center'
          }}>
            What Our Clients Say
          </h2>
          
          <div sx={{
            display: 'grid',
            gridTemplateColumns: ['1fr', null, '1fr 1fr'],
            gap: 3,
            mb: 3
          }}>
            <div sx={{
              bg: 'white',
              borderRadius: '10px',
              p: 4,
              boxShadow: '0 4px 12px rgba(0,0,0,0.05)'
            }}>
              <p sx={{ fontSize: '1rem', fontStyle: 'italic', lineHeight: 1.7, mb: 3 }}>
                "Their vendor-neutral approach gave us confidence we were selecting the best HRIS for our needs, not just the one with the best sales team. The structured process saved us months of research and prevented us from making an expensive mistake."
              </p>
              <div sx={{ fontWeight: 600, fontSize: '0.95rem' }}>Sarah Johnson</div>
              <div sx={{ fontSize: '0.9rem' }}>HR Director, Medium Enterprise (250 employees)</div>
            </div>
            
            <div sx={{
              bg: 'white',
              borderRadius: '10px',
              p: 4,
              boxShadow: '0 4px 12px rgba(0,0,0,0.05)'
            }}>
              <p sx={{ fontSize: '1rem', fontStyle: 'italic', lineHeight: 1.7, mb: 3 }}>
                "As a fast-growing startup, we needed an ATS that could scale with us. Their consultants helped us look beyond our immediate needs to find a solution that will grow with us. They saved us at least 20% on implementation costs."
              </p>
              <div sx={{ fontWeight: 600, fontSize: '0.95rem' }}>Michael Chen</div>
              <div sx={{ fontSize: '0.9rem' }}>Chief People Officer, Tech Startup (75 employees)</div>
            </div>
          </div>
          
          <div sx={{
            display: 'grid',
            gridTemplateColumns: ['1fr', null, '1fr 1fr'],
            gap: 3
          }}>
            <div sx={{
              bg: 'white',
              borderRadius: '10px',
              p: 4,
              boxShadow: '0 4px 12px rgba(0,0,0,0.05)'
            }}>
              <p sx={{ fontSize: '1rem', fontStyle: 'italic', lineHeight: 1.7, mb: 3 }}>
                "Their expertise during contract negotiations was invaluable. They identified terms we would have missed and helped us secure pricing that was well below our initial budget. The implementation roadmap they developed has kept our project on track."
              </p>
              <div sx={{ fontWeight: 600, fontSize: '0.95rem' }}>Robert Franklin</div>
              <div sx={{ fontSize: '0.9rem' }}>VP of Human Resources, Large Enterprise (1,200 employees)</div>
            </div>
            
            <div sx={{
              bg: 'white',
              borderRadius: '10px',
              p: 4,
              boxShadow: '0 4px 12px rgba(0,0,0,0.05)'
            }}>
              <p sx={{ fontSize: '1rem', fontStyle: 'italic', lineHeight: 1.7, mb: 3 }}>
                "After a failed implementation with a previous system, we were hesitant to try again. Their thorough requirements gathering and vendor evaluation process ensured we found the right fit this time. Employee adoption has been remarkably high."
              </p>
              <div sx={{ fontWeight: 600, fontSize: '0.95rem' }}>Jennifer Martinez</div>
              <div sx={{ fontSize: '0.9rem' }}>HR Manager, Healthcare Organization (400 employees)</div>
            </div>
          </div>
        </div>
      </div>
      
      {/* Ideal Client Types */}
      <div sx={{
        maxWidth: '1200px',
        mx: 'auto',
        py: 4,
        px: 3
      }}>
        <h2 sx={{
          fontSize: ['1.6rem', '1.8rem'],
          fontWeight: 700,
          mb: 3,
          textAlign: 'center'
        }}>
          Who We Serve
        </h2>
        
        <div sx={{
          display: 'grid',
          gridTemplateColumns: ['1fr', null, '1fr 1fr', 'repeat(3, 1fr)'],
          gap: 3,
          mb: 4
        }}>
          <div sx={{
            bg: 'white',
            borderRadius: '10px',
            p: 4,
            boxShadow: '0 4px 12px rgba(0,0,0,0.06)',
            transition: 'transform 0.2s',
            ':hover': {
              transform: 'translateY(-5px)',
              boxShadow: '0 8px 24px rgba(0,0,0,0.1)'
            }
          }}>
            <div sx={{ 
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '70px',
              height: '70px',
              borderRadius: '50%',
              bg: 'primaryMuted',
              mb: 3
            }}>
              <RiBuildingLine size={32} sx={{ color: 'primary' }} />
            </div>
            <h3 sx={{ 
              fontSize: '1.2rem', 
              fontWeight: 600, 
              mb: 3,
              color: 'primary' 
            }}>
              Growing Organizations
            </h3>
            <p sx={{ fontSize: '0.95rem', lineHeight: 1.6 }}>
              Companies outgrowing basic systems and needing scalable solutions that support their continuing expansion.
            </p>
          </div>
          
          <div sx={{
            bg: 'white',
            borderRadius: '10px',
            p: 4,
            boxShadow: '0 4px 12px rgba(0,0,0,0.06)',
            transition: 'transform 0.2s',
            ':hover': {
              transform: 'translateY(-5px)',
              boxShadow: '0 8px 24px rgba(0,0,0,0.1)'
            }
          }}>
            <div sx={{ 
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '70px',
              height: '70px',
              borderRadius: '50%',
              bg: 'primaryMuted',
              mb: 3
            }}>
              <RiTeamLine size={32} sx={{ color: 'primary' }} />
            </div>
            <h3 sx={{ 
              fontSize: '1.2rem', 
              fontWeight: 600, 
              mb: 3,
              color: 'primary' 
            }}>
              HR Teams Under Pressure
            </h3>
            <p sx={{ fontSize: '0.95rem', lineHeight: 1.6 }}>
              HR departments with limited bandwidth who need expert guidance to navigate complex selection decisions efficiently.
            </p>
          </div>
          
          <div sx={{
            bg: 'white',
            borderRadius: '10px',
            p: 4,
            boxShadow: '0 4px 12px rgba(0,0,0,0.06)',
            transition: 'transform 0.2s',
            ':hover': {
              transform: 'translateY(-5px)',
              boxShadow: '0 8px 24px rgba(0,0,0,0.1)'
            }
          }}>
            <div sx={{ 
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '70px',
              height: '70px',
              borderRadius: '50%',
              bg: 'primaryMuted',
              mb: 3
            }}>
              <RiMoneyDollarCircleLine size={32} sx={{ color: 'primary' }} />
            </div>
            <h3 sx={{ 
              fontSize: '1.2rem', 
              fontWeight: 600, 
              mb: 3,
              color: 'primary' 
            }}>
              Cost-Conscious Leaders
            </h3>
            <p sx={{ fontSize: '0.95rem', lineHeight: 1.6 }}>
              Organizations seeking to optimize their technology investment and avoid costly selection mistakes.
            </p>
          </div>
        </div>
      </div>
      
      {/* New Footer CTA Section */}
      <div sx={{
        bg: '#f2f5f8',
        py: [4, 5],
        mt: 4
      }}>
        <div sx={{
          maxWidth: '1000px',
          mx: 'auto',
          px: 3,
          textAlign: 'center'
        }}>
          <h2 sx={{
            fontSize: ['1.8rem', '2.3rem'],
            fontWeight: 700,
            color: '#081c50',
            mb: 2
          }}>
            Ready to Select the Right HR Software?
          </h2>
          
          <p sx={{
            fontSize: ['1rem', '1.2rem'],
            color: 'text',
            mb: [3, 4],
            lineHeight: 1.6,
            maxWidth: '800px',
            mx: 'auto'
          }}>
            Schedule a consultation with our HR technology experts to discuss your organization's
            unique needs and learn how we can help you select the perfect HR software solution that 
            drives efficiency, compliance, and employee satisfaction.
          </p>
          
          <div sx={{
            display: 'flex',
            flexDirection: ['column', 'row'],
            gap: 4,
            justifyContent: 'center',
            maxWidth: '600px',
            mx: 'auto'
          }}>
            <Link to="/contact" sx={{
              bg: '#081c50',
              color: 'white',
              py: '12px',
              px: '24px',
              borderRadius: '5px',
              fontWeight: 600,
              textDecoration: 'none',
              fontSize: '1rem',
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              transition: 'background-color 0.2s, transform 0.2s',
              ':hover': {
                bg: '#0a2166',
                color: 'white'
              }
            }}>
              Schedule a Consultation <RiCalendarCheckLine sx={{ ml: 2 }} />
            </Link>
            
            <Link to="/contact" sx={{
              bg: 'white',
              color: '#081c50',
              py: '12px',
              px: '24px',
              borderRadius: '5px',
              fontWeight: 600,
              textDecoration: 'none',
              fontSize: '1rem',
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: '1px solid #081c50',
              transition: 'background-color 0.2s, transform 0.2s',
              ':hover': {
                bg: '#f5f5f5'
              }
            }}>
              Contact Sales <RiMailLine sx={{ ml: 2 }} />
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default HRSoftwareSelectionPage; 